<template>
  <v-container fluid>
    <v-row align-content="end">
      <v-col :cols="item.cols" v-for="(item, i) in modulosFiltrados" :key="i">
        <v-card class="mx-auto" :color="item.cardColor" dark max-width="400">
          <v-card-title>
            <v-icon large left :class="[item.textColor]">
              {{ item.icono }}
            </v-icon>
            <span :class="['text-h6 font-weight-light', item.textColor]">{{
              item.no_gestiones
            }}</span>
          </v-card-title>

          <v-card-text>
            <v-btn :href="item.url" :title="`Click para ir a : ${item.titulo}`" text x-small
              :class="['caption', item.textColor]">{{ item.titulo }}</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="text-center mt-4">
      <v-col v-for="(item, i) in modulos" :key="i" cols="4">
        <v-card tile>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-subtitle-1 mt-2 text-left">
                {{ item.titulo }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-caption text-left text--secondary font-weight-regular">
                {{ item.descripcion }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar class="ma-3" size="50" tile>
              <v-icon color="#031D6A">{{ item.icono }}</v-icon>
            </v-list-item-avatar>
          </v-list-item>

          <v-card-actions>
            <v-btn class="ml-2 mb-1 white--text" rounded small color="#031D6A" :to="item.url">
              IR
            </v-btn>
          </v-card-actions>
        </v-card>

        <!-- <v-card class="card-outter" height="100%">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <p class="text-left ml-6 mt-4 font-weight-medium">
                {{ item.titulo }}
              </p>
              <p class="text-left ml-6 mt-n4 text-body-2 text--secondary">
                {{ item.descripcion }}
              </p>

              <v-card-actions>
                <v-btn
                  class="ml-4 white--text"
                  rounded
                  small
                  color="#031D6A"
                  :href="item.url"
                >
                  IR
                </v-btn>
              </v-card-actions>
            </div>

            <v-avatar class="ma-3" size="40" tile>
              <v-icon color="#031D6A">{{ item.icono }}</v-icon>
            </v-avatar>
          </div>
        </v-card> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');
export default {
  name: 'mainmenu',
  data() {
    return {
      total: 10, //variable temporal para mostrar el número en las cards

      //items de prueba para las card superiores
      gestiones_por_estado: [
        {
          //0
          icono: 'mdi-clipboard-edit-outline',
          titulo: 'Precalificaciones incompletas',
          cardColor: '#FFE0B2',
          textColor: 'amber--text text--darken-4',
          color: 'color-uno',
          no_gestiones: 999,
          url: '/precalificaciones-incompletas',
          cols: 3,
        },
        {
          //1
          icono: 'mdi-clipboard-edit',
          titulo: 'Precalificaciones completadas',
          cardColor: '#C8E6C9',
          textColor: 'green--text text--darken-3',
          color: 'color-dos',
          no_gestiones: 0,
          url: '/precalificaciones-completadas',
          cols: 3,
        },
        {
          //2
          icono: 'mdi-clipboard-text-play',
          titulo: 'En revisión',
          cardColor: '#C5CAE9',
          textColor: 'light-blue--text text--darken-4',
          color: 'color-tres',
          no_gestiones: 0,
          url: '/gestiones-en-revision',
          cols: 2,
        },
        {
          //3
          icono: 'mdi-clipboard-clock',
          titulo: 'Pendientes',
          cardColor: '#EDE7F6',
          textColor: 'purple--text text--lighten-2',
          color: 'color-tresnew',
          no_gestiones: 0,
          url: '/gestiones-con-pendientes',
          cols: 2,
        },
        {
          //4
          icono: 'mdi-receipt-text-clock',
          titulo: 'Con rectificación',
          cardColor: '#B2EBF2',
          textColor: 'cyan--text text--darken-3',
          color: 'color-cuatro',
          no_gestiones: 0,
          url: '/gestiones-con-rectificaciones',
        },
        {
          //5
          icono: 'mdi-clipboard-account',
          titulo: 'Con compromiso de pago',
          cardColor: '#FCE4EC',
          textColor: 'pink--text text--accent-3',
          color: 'color-cinco',
          no_gestiones: 0,
          url: '/gestiones-con-compromiso-de-pago',
        },
        {
          //6
          icono: 'mdi-clipboard-check',
          titulo: 'Listos para aprobación',
          cardColor: '#FFF3E0',
          textColor: 'orange--text text--darken-3',
          color: 'color-seis',
          no_gestiones: 0,
          url: '/gestiones-listas-para-aprobacion',
        },
        {
          //7
          icono: 'mdi-clipboard-check-multiple-outline',
          titulo: 'Gestiones concluidas',
          cardColor: '#BBDEFB',
          textColor: 'light-blue--text text--darken-3',
          color: 'color-siete',
          no_gestiones: 0,
          url: '/gestiones-concluidas',
        },
      ],

      //modulos de prueba para mostrar en las cards inferiores
      modulos: [],
    };
  },
  methods: {
    ...mapActions('Gestiones', {
      findGestiones: 'find',
      getGestiones: 'get',
      patchGestiones: 'patch',
    }),
    async main() {
      /***
       *
       * CONTEO DE GESTIONES POR ESTAPA
       *
       * */
      //titulo: 'Precalificaciones incompletas',
      await this.findGestiones({
        query: {
          flag_precalificacion_incompleta: 1,
          flag_precalificacion_eliminada: {
            $eq: null,
          },
          $or: [
            {
              flag_precalificacion_completada: {
                $eq: null,
              }
            },
            {
              flag_precalificacion_completada: {
                $eq: 0,
              }
            }

          ]
          ,
        },
      }).then((r) => {
        this.gestiones_por_estado[0].no_gestiones = r.total;
      });
      //titulo: 'Precalificaciones completadas',
      await this.findGestiones({
        query: {
          flag_precalificacion_completada: 1,
          flag_precalificacion_en_revision: {
            $eq: null,
          },
        },
      }).then((r) => {
        this.gestiones_por_estado[1].no_gestiones = r.total;
      });
      //titulo: 'En revisión',
      await this.findGestiones({
        query: {
          flag_precalificacion_en_revision: 1,
          flag_precalificacion_con_pendientes: {
            $eq: null,
          },
          flag_precalificacion_con_rectificacion: {
            $eq: null,
          },
          flag_precalificacion_con_compromiso_de_pago: {
            $eq: null,
          },
          flag_precalificacion_listo_para_aprobacion: {
            $eq: null,
          },
          flag_precalificacion_concluidas: {
            $eq: null,
          },
          flag_precalificacion_cancelada: {
            $eq: null,
          },
          flag_precalificacion_eliminada: {
            $eq: null,
          },
        },
      }).then((r) => {
        this.gestiones_por_estado[2].no_gestiones = r.total;
      });
      //titulo: 'Pendientes',
      await this.findGestiones({
        query: {
          $limit: 1,

          flag_precalificacion_cancelada: {
            $eq: null,
          },

          flag_precalificacion_con_pendientes: 1,
        },
      }).then((r) => {
        this.gestiones_por_estado[3].no_gestiones = r.total;
      });
      //titulo: 'Con rectificación',
      await this.findGestiones({
        query: {
          $limit: 1,
          flag_precalificacion_con_rectificacion: 1,
          flag_precalificacion_cancelada: {
            $eq: null,
          },
          flag_precalificacion_listo_para_aprobacion: {
            $eq: null,
          },
        },
      }).then((r) => {
        this.gestiones_por_estado[4].no_gestiones = r.total;
      });
      //titulo: 'Con compromiso de pago',
      await this.findGestiones({
        query: {
          $limit: 1,
          flag_precalificacion_con_compromiso_de_pago: 1,
          flag_precalificacion_cancelada: {
            $eq: null,
          },
          flag_precalificacion_listo_para_aprobacion: {
            $eq: null,
          },
        },
      }).then((r) => {
        this.gestiones_por_estado[5].no_gestiones = r.total;
      });
      //titulo: 'Listos para aprovación',
      await this.findGestiones({
        query: {
          $limit: 1,
          flag_precalificacion_listo_para_aprobacion: 1,
          flag_precalificacion_cancelada: {
            $eq: null,
          },
          flag_precalificacion_concluidas: {
            $eq: null,
          },
        },
      }).then((r) => {
        this.gestiones_por_estado[6].no_gestiones = r.total;
      });
      //titulo: 'Gestiones concluidas',
      await this.findGestiones({
        query: {
          $limit: 1,
          flag_precalificacion_concluidas: 1,
        },
      }).then((r) => {
        this.gestiones_por_estado[7].no_gestiones = r.total;
      });
      /***
       *
       * CONTEO DE GESTIONES POR ESTAPA, FIN
       *
       * */
    },
    fnGetDate(date) {
      return date ? moment(date).format('DD-MM-YYYY HH:mm a') : '';
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.payload.usuario;
    },
    modulosFiltrados() {
      let modulosPermitidos = [];

      if (this.currentUser.rol_nombre == 'Administrador') {
        // Filtrar los módulos que tengan el atributo 'tipo: menuPrincipalTopSection'
        modulosPermitidos = this.$store.state.modulos.filter(modulo =>
          modulo.tipo === 'menuPrincipalTopSection'
        );
      } else {
        // Filtrar los módulos por permisos y también por el atributo 'tipo'
        modulosPermitidos = this.$store.state.modulos.filter(modulo =>
          JSON.parse(this.currentUser.permisos_modulos).includes(modulo.url) &&
          modulo.tipo === 'menuPrincipalTopSection'
        );
      }

      // Hacer match con gestiones_por_estado basado en el url
      return this.gestiones_por_estado.filter(gestion =>
        modulosPermitidos.some(modulo => modulo.url === gestion.url)
      );
    }
  },
  mounted() {
    if (this.currentUser.rol_nombre == 'Administrador') {
      // Filtrar los módulos que tengan el atributo 'tipo: menuPrincipalOpcionesAbajo'
      this.modulos = this.$store.state.modulos.filter(modulo => modulo.tipo === 'menuPrincipalOpcionesAbajo');
    } else {
      // Filtrar los módulos por permisos y también por el atributo 'tipo'
      this.modulos = this.$store.state.modulos.filter(modulo =>
        JSON.parse(this.currentUser.permisos_modulos).includes(modulo.url) &&
        modulo.tipo === 'menuPrincipalOpcionesAbajo'
      );
    };
    /** */
    this.main();
    /** */
    const { Gestiones, CompromisosDePago } = this.$FeathersVuex.api;
    Gestiones.on('created', () => {
      this.main();
    });
    Gestiones.on('patched', () => {
      this.main();
    });
    CompromisosDePago.on('created', () => {
      this.main();
    });
  },
};
</script>

<style scoped>
.color-uno {
  color: #ff8f00;
}

.color-dos {
  color: #4caf50;
}

.color-tres {
  color: #031d6a;
}

.color-tresnew {
  color: #ba68c8;
}

.color-cuatro {
  color: #0097a7;
}

.color-cinco {
  color: #ff80ab;
}

.color-seis {
  color: #ffcc80;
}

.color-siete {
  color: #0277bd;
}
</style>
